import PropTypes from "prop-types";
import { useState } from "react";
import useFetch from "hooks/use-fetch";
import { post } from "utils/api";
import { Card } from "@mui/material";
import useTranslate from "hooks/use-translate-language";
import { NewOrderErrorPage } from "./error-page";
import { LoadingButton } from "components/ui";
import { Formik, Form } from "formik";
import FormikField from "components/form/formik-field";
import * as Yup from "yup";

const ValidatePatientDob = ({ token, authCode, children, onSuccess }) => {
  const translate = useTranslate();

  const [numberOfAttempts, setNumberOfAttempts] = useState(1);
  const [dobValidated, setDobValidated] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const checkDOBApi = useFetch({
    apiFn: ({ dateOfBirth, numberOfAttempts }) =>
      authCode?.length > 0
        ? post(
            "patient_portal/mobile/confirm_dob_auth",
            { dateOfBirth, numberOfAttempts },
            authCode
          )
        : post(
            "patient_portal/mobile/confirm_dob",
            { dateOfBirth, numberOfAttempts },
            token
          ),
    onSuccess: result => {
      if (result.success) {
        if (typeof onSuccess == "function") onSuccess(result);
        setDobValidated(true);
      } else setNumberOfAttempts(x => x + 1);
    },
    onError: err => {
      setNumberOfAttempts(x => x + 1);
      console.error(err);
    },
    defaultValue: {
      success: false
    }
  });

  if (dobValidated) return children;
  if (errorMessage) return <NewOrderErrorPage errorMessage={errorMessage} />;
  return (
    <div>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <Formik
          initialValues={{ dateOfBirth: "" }}
          onSubmit={async ({ dateOfBirth }) => {
            try {
              await checkDOBApi.fetchData({
                dateOfBirth,
                numberOfAttempts
              });
            } catch (err) {
              console.error(err);
              setErrorMessage(err.message);
            }
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            dateOfBirth: Yup.date().required(translate("selectADate"))
          })}
        >
          {({ isSubmitting, errors }) => (
            <Form className="patient-form" autoComplete="off">
              <Card style={{ padding: 30 }}>
                <h4>{translate("confirmDateOfBirth")}</h4>
                <div
                  style={{
                    padding: "15px 10px",
                    alignItems: "center",
                    display: "grid",
                    gridTemplateColumns: "1fr auto",
                    gridGap: 10
                  }}
                >
                  <FormikField label="" name="dateOfBirth" type="date" />
                  <LoadingButton
                    color="primary"
                    type="submit"
                    isFetching={isSubmitting}
                    disabled={errors.dateOfBirth}
                  >
                    {translate("submit")}
                  </LoadingButton>
                </div>
                {numberOfAttempts > 1 && (
                  <div>{translate("tryAgainError")}</div>
                )}
              </Card>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

ValidatePatientDob.propTypes = {
  children: PropTypes.object.isRequired,
  token: PropTypes.string,
  authCode: PropTypes.string,
  onSuccess: PropTypes.func
};

export default ValidatePatientDob;
