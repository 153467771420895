import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@mui/material";
import { ErrorMessage } from "formik";

const SelectInputField = ({
  label,
  field,
  form,
  options,
  className,
  ...rest
}) => {
  return (
    <div className={className}>
      <span className="form-field-header">
        {label && <label className="form-field-label">{label}</label>}
      </span>
      <span className="form-field-error">
        <ErrorMessage name={field.name} />
      </span>
      <Select
        name={field.name}
        value={field.value}
        type="radio"
        className="input-field"
        onChange={form.handleChange}
        onBlur={x => {
          field.onBlur(x);
        }}
        style={{ margin: 0 }}
        {...rest}
      >
        {options.map(({ text, value }) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

SelectInputField.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      test: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    })
  ).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onBlur: PropTypes.func.isRequired
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired
  }).isRequired,
  className: PropTypes.string
};

SelectInputField.defaultProps = {
  className: "form-field"
};

export default SelectInputField;
