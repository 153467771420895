import React from "react";
import { useFormikContext, FieldArray } from "formik";
import { formatDate } from "utils/misc";
import supplyOptions from "./supply-options";
import { OrderFormContext } from "./provider";
import useTranslate from "hooks/use-translate-language";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button
} from "@mui/material";

const ChooseSupplies = () => {
  const translate = useTranslate();
  const { patientHCPCHistory, supply_options } =
    React.useContext(OrderFormContext);

  const { values, errors } = useFormikContext();

  // var selectedAllEligibleSupplies = eligibleSupplies.every(
  //   x =>
  //     values.requested_equipment.includes(x) &&
  //     eligibleSupplies.length == values.requested_equipment.length
  // );
  const [ineligibleSuppliesDisclaimer, setIneligibleSuppliesDisclaimer] =
    React.useState();

  return (
    <FieldArray
      name="requested_equipment"
      render={selectedEquipment => (
        <div style={{ paddingBottom: 20 }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className="eligbleDisclaimer"
              style={{
                color: "#4d4d4d"
              }}
            >
              {translate("suppliesDisclaimer")}
            </div>
            <ul style={{ margin: 5, padding: 0 }}>
              {supplyOptions
                .filter(x => supply_options?.includes(x.value))
                .map(({ value, text, description }) => {
                  const thisItem = patientHCPCHistory?.find(phh =>
                    phh.productType.toLowerCase()?.includes(value.toLowerCase())
                  );
                  return (
                    <li
                      key={value}
                      style={{
                        listStyleType: "none"
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "15px"
                          }}
                        >
                          <input
                            name="requested_equipment"
                            type="checkbox"
                            value={value}
                            checked={values.requested_equipment.includes(value)}
                            onChange={e => {
                              if (thisItem == null || thisItem.isEligible) {
                                if (e.target.checked)
                                  selectedEquipment.push(value);
                                else {
                                  const idx =
                                    values.requested_equipment.indexOf(value);
                                  selectedEquipment.remove(idx);
                                }
                              } else {
                                if (!thisItem.isEligible) {
                                  if (e.target.checked) {
                                    setIneligibleSuppliesDisclaimer(value);
                                  } else {
                                    const idx =
                                      values.requested_equipment.indexOf(value);
                                    selectedEquipment.remove(idx);
                                  }
                                }
                              }
                            }}
                          />
                        </div>
                        <div>
                          <div style={{ display: "flex" }}>
                            <div>
                              {text}
                              {"   "}
                            </div>{" "}
                            {thisItem && (
                              <div
                                className={
                                  thisItem.isEligible
                                    ? "eligible"
                                    : "notEligible"
                                }
                              >
                                {thisItem.isEligible
                                  ? "Eligible"
                                  : formatDate(
                                      thisItem.eligibilityDate,
                                      "MM/DD/YYYY"
                                    )}
                              </div>
                            )}
                          </div>
                          <div className="description">{description}</div>
                        </div>
                      </label>
                    </li>
                  );
                })}
            </ul>
          </div>
          {errors.requested_equipment && (
            <div
              className="order-form-warning"
              style={{
                padding: "10px",
                border: "1px solid #ff3333",
                color: "#ff3333",
                background: "#ffcccc33",
                fontWeight: "600",
                margin: "5px 0"
              }}
            >
              {errors.requested_equipment}
            </div>
          )}

          {ineligibleSuppliesDisclaimer && (
            <Dialog
              open={open}
              onClose={() => {
                setIneligibleSuppliesDisclaimer();
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {translate("inelgibleSuppliesDisclaimer")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setIneligibleSuppliesDisclaimer();
                  }}
                  color="primary"
                >
                  No
                </Button>
                <Button
                  onClick={() => {
                    selectedEquipment.push(ineligibleSuppliesDisclaimer);
                    setIneligibleSuppliesDisclaimer();
                  }}
                  color="primary"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      )}
    />
  );
};

export default ChooseSupplies;
