import React from "react";
import useFetch from "hooks/use-fetch";
import apiRequest from "utils/api";
import { NewOrderErrorPage } from "components/new-email-order/error-page";
import { Spinner } from "components/ui";
import PropTypes from "prop-types";
import { NotificationManager } from "react-notifications";

export const OrderFormContext = React.createContext({});

const OrderFormProvider = ({
  patientId,
  token,
  origin,
  productLine,
  children,
  handleCreateOrderSuccess
}) => {
  const orderFormInfoAPI = useFetch({
    apiFn: ({ patientId, productLine }) =>
      apiRequest("patient_portal/mobile/order_form", {
        method: "POST",
        body: { patientId, productLine },
        headers: {
          authorization: `Bearer ${token}`
        }
      }),
    defaultValue: {
      questions: [],
      last_order: {},
      supply_options: [],
      supply_period_options: []
    }
  });

  const patientFormAttributesAPI = useFetch({
    apiFn: () =>
      apiRequest(`patient_portal/mobile/form_attributes`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`
        }
      }),
    defaultValue: {
      insurance_companies: [],
      guarantor_options: []
      //company_order_settings: {}
    }
  });

  const patientProfileAPI = useFetch({
    apiFn: patientId =>
      apiRequest(`patient_portal/mobile/${patientId}/info`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`
        }
      }),
    defaultValue: {}
  });

  const patientHCPCHistoryAPI = useFetch({
    apiFn: patientId =>
      apiRequest(`patient_portal/mobile/${patientId}/hcpcs-ordered`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`
        }
      }),
    defaultValue: { eligibility: [], outreachDate: "" }
  });

  const submitOrderAPI = useFetch({
    apiFn: values =>
      apiRequest(`patient_portal/mobile/create-new-order`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`
        },
        body: { ...values }
      }),
    onSuccess: msg => {
      NotificationManager.success("Your Order has been submitted");
      handleCreateOrderSuccess(msg);
    },
    onError: err => NotificationManager.error(err.message, "Order Error")
  });

  React.useEffect(() => {
    orderFormInfoAPI.fetchData({
      patientId,
      productLine
    });
    patientHCPCHistoryAPI.fetchData(patientId);
    patientFormAttributesAPI.fetchData(patientId);
    patientProfileAPI.fetchData(patientId);
  }, [token]);

  if (
    orderFormInfoAPI.isFetching ||
    patientFormAttributesAPI.isFetching ||
    patientHCPCHistoryAPI.isFetching ||
    patientProfileAPI.isFetching
  ) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (
    orderFormInfoAPI.error ||
    patientFormAttributesAPI.error ||
    patientHCPCHistoryAPI.error ||
    patientProfileAPI.error
  ) {
    return <NewOrderErrorPage />;
  }

  const { questions, supply_options, supply_period_options, last_order } =
    orderFormInfoAPI.response;

  const eligibleSupplies =
    patientHCPCHistoryAPI?.response?.eligiblity
      ?.filter(
        x =>
          x.isEligible &&
          x.productType &&
          supply_options.includes(x.productType.toLowerCase())
      )
      .map(x => x.productType.toLowerCase()) ?? [];

  const { insurance_companies, guarantor_options } =
    patientFormAttributesAPI.response;

  const supplyPeriodOptions = (supply_period_options ?? []).map(x => ({
    text: `${x} Day Supply`,
    value: String(x)
  }));

  return (
    <OrderFormContext.Provider
      value={{
        productLine,
        patientId,
        patientInfo: patientProfileAPI?.response,
        patientHCPCHistory: patientHCPCHistoryAPI.response?.eligiblity,
        questions,
        supply_options,
        eligibleSupplies,
        supplyPeriodOptions,
        last_order,
        insurance_companies,
        guarantor_options,
        order_create_method: origin,
        submitOrderAPI
      }}
    >
      {children}
    </OrderFormContext.Provider>
  );
};

OrderFormProvider.propTypes = {
  patientId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  origin: PropTypes.string,
  productLine: PropTypes.string,
  children: PropTypes.node,
  handleCreateOrderSuccess: PropTypes.func
};

export default OrderFormProvider;
