const supplyOptions = [
  // CPAP supplies
  {
    value: "mask",
    text: "Mask",
    description:
      "Includes the frame as well as the seal/cushion/pillow of your mask and the headgear when eligible",
    replacementSchedule: "Once every 3 months"
  },
  {
    value: "headgear",
    text: "Headgear",
    description:
      "Neoprene Straps that go around your head and holds the mask in place",
    replacementSchedule: "Included with mask when eligible"
  },
  {
    value: "seals",
    text: "Seal / Cushion / Pillow",
    description:
      "Soft silicone replacement portion of your mask that touches your face.",
    replacementSchedule:
      "Full face: 3 per 3 months, Nasal/Pillow: 6 per 3 months."
  },
  {
    value: "standard tubing",
    text: "Standard Tubing",
    description:
      "Corrugated tubing that runs from your mask to your pap machine.",
    replacementSchedule: "Once every 3 months."
  },
  {
    value: "heated tubing",
    text: "Heated Tubing",
    description:
      "Corrugated heated tubing that runs from your mask to your pap machine.",
    replacementSchedule: "Once every 3 months."
  },
  {
    value: "tubing",
    text: "Tubing",
    description:
      "Corrugated tubing that runs from your mask to your pap machine.",
    replacementSchedule: "Once every 3 months."
  },
  {
    value: "non_heated_tubing",
    text: "Non Heated Tubing",
    description:
      "Corrugated tubing that runs from your mask to your pap machine.",
    replacementSchedule: "Once every 3 months."
  },
  {
    value: "heated_tubing",
    text: "Heated Tubing",
    description:
      "Corrugated tubing that runs from your mask to your pap machine.",
    replacementSchedule: "Once every 3 months."
  },
  {
    value: "disposable filter",
    text: "Disposable Filters",
    description: "Disposable Machine filter",
    replacementSchedule: "Disposable: 6 per 3 months "
  },
  {
    value: "nondisposable filter",
    text: "Non-Disposable Filters",
    description: "Non-Disposable Machine filter",
    replacementSchedule: "Non-Disposable: 1 every 6 months."
  },
  {
    value: "chinstrap",
    text: "Chinstrap",
    description:
      "Neoprene strap that sets under the chin to help hold your mouth closed",
    replacementSchedule: "Once every 6 months"
  },
  {
    value: "water chamber",
    text: "Water Chamber",
    description: "Reservoir that holds water for humidifier",
    replacementSchedule: "Once every 6 to 12 months"
  },
  // CGM supplies
  {
    value: "cgm sensor",
    text: "CGM Sensor",
    description: "",
    replacementSchedule: ""
  },
  {
    value: "strips",
    text: "Strips",
    description: "Testing Strips",
    replacementSchedule: ""
  },
  {
    value: "control solution",
    text: "Control Solution",
    description: "Solution",
    replacementSchedule: ""
  },
  {
    value: "lancets",
    text: "Lancets",
    description: "Lancets",
    replacementSchedule: ""
  }
];

export default supplyOptions;
