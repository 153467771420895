import React from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import {
  RadioGroupField,
  SelectInputField,
  TextInputField,
  DateInputField
} from "components/form";
import { questionValidation } from "./validation";

const FormElement = ({ id, type, options, disabled, text, ...rest }) => {
  const { values } = useFormikContext();
  switch (type) {
    case "Yes/No":
      return (
        <Field
          component={RadioGroupField}
          name={`questions.${id}`}
          label={text}
          value={values.questions[id]}
          disabled={disabled}
          options={[
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]}
          validate={questionValidation(id)}
          {...rest}
        />
      );
    case "Multiple Choice":
      return (
        <>
          <div style={{ marginBottom: 10 }}>
            <Field
              component={SelectInputField}
              name={`questions.${id}`}
              label={text}
              value={values.questions[id]}
              disabled={disabled}
              options={[{ text: "", value: undefined }, ...options]}
              validate={questionValidation(id)}
              {...rest}
            />
          </div>
          <ChildQuestion options={options} id={id} values={values} />
        </>
      );
    case "Text":
      return (
        <div style={{ marginBottom: 10 }}>
          <Field
            component={TextInputField}
            name={`questions.${id}`}
            label={text}
            value={values.questions[id]}
            disabled={disabled}
            validate={questionValidation(id)}
            type="text"
            {...rest}
          />
        </div>
      );
    case "Disclaimer":
      return (
        <div
          className="order-form-warning"
          style={{
            padding: "10px",
            border: "1px solid #ff3333",
            color: "#ff3333",
            background: "#ffcccc33",
            fontWeight: "600",
            margin: "10px 0"
          }}
          {...rest}
        >
          {text}
        </div>
      );
    case "Date":
      return (
        <div style={{ marginBottom: 10 }}>
          <Field
            component={DateInputField}
            name={`questions.${id}`}
            label={text}
            value={values.questions[id]}
            disabled={disabled}
            validate={questionValidation(id)}
            type="text"
            {...rest}
          />
        </div>
      );
    default:
      return null; //JSON.stringify({ type, options, text });
  }
};

FormElement.propTypes = {
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])
    })
  ),
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default FormElement;

function ChildQuestion({ options, id, values }) {
  const childQuestions = options.filter(x => x.question);
  const child = childQuestions.find(
    x => x.value === values.questions[id]
  )?.question;
  const { setValues } = useFormikContext();
  React.useEffect(() => {
    if (child == null && childQuestions.length > 0) {
      const clearedValues = childQuestions.reduce((acc, cur) => {
        return { [cur.question.id]: undefined, ...acc };
      }, {});
      setValues({
        ...values,
        questions: { ...values.questions, ...clearedValues }
      });
    }
  }, [child]);
  if (child != null) return <FormElement {...child} />;
  return null;
}
ChildQuestion.propTypes = {
  values: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])
    })
  ),
  id: PropTypes.string.isRequired
};
