import React from "react";
import PropTypes from "prop-types";
import { TextInput, SelectInput, RadioGroupField } from "components/form";
import { STATES } from "utils/enum";
import { useFormikContext, Field } from "formik";
import FormElement from "./form-elements";
import useTranslate from "hooks/use-translate-language";
import { isValidZip } from "utils/misc";

const AddressForm = ({ patientInfo, questions }) => {
  const translate = useTranslate();
  const { values, setFieldValue } = useFormikContext();

  React.useEffect(() => {
    if (values.questions.address_change === false) {
      setFieldValue("address", patientInfo.address);
    }
  }, [values.questions.address_change]);

  const address_change_question = questions.find(
    q => q.id === "address_change"
  );

  if (!address_change_question) {
    return null;
  }

  const disabled = !values.questions.address_change;

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      {/* <h3>{translate("shippingDetails")}</h3> */}
      <div>
        <Field
          component={RadioGroupField}
          name="questions.address_change"
          label={translate("addressChanged")}
          value={values.questions.address_change}
          options={[
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]}
        />
        <div style={{ paddingBottom: 20, minHeight: 30 }}>
          {values.questions?.address_change && (
            <Field
              component={RadioGroupField}
              name="address_change_type"
              label=""
              value={values.address_change_type}
              required
              options={[
                { value: "P", text: "Permanent Address Change" },
                { value: "T", text: "Temporary Address Change" }
              ]}
            />
          )}
        </div>
      </div>
      <TextInput
        name="address.street_address"
        label={translate("streetAddress")}
        disabled={disabled}
        style={{ padding: 5 }}
      />
      <TextInput
        name="address.street_address2"
        label={translate("streetAddress2")}
        disabled={disabled}
        style={{ padding: 5 }}
      />
      <TextInput
        name="address.city"
        label={translate("city")}
        disabled={disabled}
        style={{ padding: 5 }}
      />
      <SelectInput
        name="address.state"
        label={translate("state")}
        options={STATES}
        disabled={disabled}
        style={{ marginTop: 10, marginBottom: 10, marginLeft: 3 }}
      />
      <TextInput
        name="address.zip"
        label={translate("zip")}
        disabled={disabled}
        validate={isValidZip}
        style={{ padding: 5 }}
        type="number"
      />
      <hr />
      <div>
        {questions
          ?.filter(x => x.id != "address_change")
          ?.map(x => (
            <FormElement key={x.id} {...x} />
          ))}
      </div>
    </div>
  );
};

export default AddressForm;

AddressForm.propTypes = {
  patientInfo: PropTypes.shape({
    address: PropTypes.shape({
      street_address: PropTypes.string,
      street_addres2: PropTypes.string,
      city: PropTypes.string,
      zip: PropTypes.string
    }).isRequired
  }).isRequired,
  questions: PropTypes.array.isRequired
};
