import { Router, Switch, Redirect, Route } from "react-router-dom";
import browserHistory from "./browser-history";
import AppRoutes from "routes";
import ProtectedRoute from "routes/protected-route";
import AppLayout from "components/app-layout";
import { useSelector } from "react-redux";
import LoginView from "components/login-pages";
import ResetPassword from "components/reset-password";
import UpdateLanguage from "components/update-language";
import NewEmailOrder from "components/new-email-order";
import ExternalOrderFormContainer, {
  SMSOrderFormContainer
} from "components/order-form-new/container";
import TextConsentPage from "components/text-consent";
import ThankYouPage from "components/thank-you";
import SignQuestionnaire from "components/questionnaires/sign-questionnaire";
import ExpiredPassword from "components/expired-password";
import SingleSigninPage from "components/single-signin-page";
import ErrorPage from "components/error-page";
import NotFoundPage from "components/not-found";
import selectors from "selectors";
import "./App.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import PushOutreach from "components/push-outreach";
import EmailRecallPage from "components/recall-notice/email-recall-page";
import PendingAppointment from "components/pending-appointment/pending-appointment";
import ComplianceForm from "components/compliance-forms";

if (process.env.NODE_ENV === "production")
  Sentry.init({
    dsn: "https://cb47519ddeb1464b923bcd39de2defa9@sentry.io/1820561",
    defaultIntegrations: false,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      }),
      new Sentry.Integrations.Breadcrumbs(),
      new Sentry.Integrations.HttpContext(),
      new Sentry.Integrations.TryCatch()
    ]
  });

const App = () => {
  const authIsValid = useSelector(selectors.getUserAuthValid);
  return (
    <Router history={browserHistory}>
      <Switch>
        <Route path="/(login|forgot-password|register)" component={LoginView} />
        <Route path="/password-reset" component={ResetPassword} />
        <Route path="/push-outreach" component={PushOutreach} />
        <Route path="/update-language" component={UpdateLanguage} />
        <Route path="/sso*" component={SingleSigninPage} />
        <Route path="/order-form/" component={ExternalOrderFormContainer} />
        <Route path="/sms-order-form/" component={SMSOrderFormContainer} />
        {/* Rotech External order form */}
        <Route
          path="/portal-order-form/"
          component={ExternalOrderFormContainer}
        />
        {/* should be deprecated */}
        <Route path="/new-email-order/" component={NewEmailOrder} />
        {/* should be deprecated */}
        <Route path="/new-text-order/" component={NewEmailOrder} />
        <Route path="/text-consent/:token*" component={TextConsentPage} />
        <Route path="/thanks" component={ThankYouPage} />
        <Route path="/error" component={ErrorPage} />
        <Route path="/password-expired" component={ExpiredPassword} />
        <Route path="/recall-notice" component={EmailRecallPage} />
        <Route path="/new-appointment/" component={PendingAppointment} />
        <Route
          path="/sign-questionnaires/:questionnaire_id"
          component={SignQuestionnaire}
        />
        <Route path="/fill-form" component={ComplianceForm} />
        <ProtectedRoute path="/" allowed={authIsValid} redirectPath="/login">
          <AppLayout>
            <Switch>
              {AppRoutes.map(({ component: Component, ...route }) => (
                <Route key={route.path} {...route}>
                  <Component />
                </Route>
              ))}
              <Redirect from="/" to="/dashboard" exact />
              <Route component={NotFoundPage} />
            </Switch>
          </AppLayout>
        </ProtectedRoute>
      </Switch>
    </Router>
  );
};

export default App;
