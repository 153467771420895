import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { ErrorMessage } from "formik";

const TextInputField = ({
  label,
  field,
  form,
  className,
  ...rest
}) => {
  return (
    <div className={className}>
      <span className="form-field-header">
        {label && <label className="form-field-label">{label}</label>}
      </span>
      <span className="form-field-error">
        <ErrorMessage name={field.name} />
      </span>
      <TextField
        name={field.name}
        value={field.value}
        className="input-field"
        onChange={form.handleChange}
        onBlur={field.onBlur}
        margin="dense"
        style={{ width: "100%" }}
        {...rest}
      />
    </div>
  );
};

TextInputField.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onBlur: PropTypes.func
  }),
  form: PropTypes.shape({
    handleChange: PropTypes.func
  }).isRequired,
  className: PropTypes.string
};

TextInputField.defaultProps = {
  className: "form-field"
};

export default TextInputField;
