import { useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ErrorMessage } from "formik";
import PropTypes from "prop-types";

const DateInputField = ({ label, field, form, className, ...rest }) => {
  useEffect(() => {
    form.setFieldTouched(field.name);
  }, []);
  return (
    <div className={className}>
      <span className="form-field-header">
        {label && <label className="form-field-label">{label}</label>}
      </span>
      <span className="form-field-error">
        <ErrorMessage name={field.name} />
      </span>

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          name={field.name}
          value={field.value}
          className="input-field"
          sx={{ width: 260 }}
          slotProps={{
            field: {
              clearable: true,
              onClear: () => form.setFieldValue(field.name, undefined)
            }
          }}
          onChange={value => {
            if (value && value.isValid) {
              form.setFieldValue(field.name, value.format("MM/DD/YYYY"));
            } else {
              form.setFieldValue(field.name, undefined);
            }
          }}
          renderInput={params => <TextField {...params} />}
          {...rest}
        />
      </LocalizationProvider>
    </div>
  );
};

DateInputField.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onBlur: PropTypes.func
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func
  }).isRequired,
  className: PropTypes.string
};

DateInputField.defaultProps = {
  className: "form-field"
};

export default DateInputField;
