import moment from "moment";
import jwt_decode from "jwt-decode";
import Translations from "translations";

export const formatDate = (date, format = "L") =>
  date ? moment(date).format(format) : "";

export const formatDateToLocal = (date, speaksSpanish, format = "L") => {
  const lang = speaksSpanish ? "es" : "en";
  return date ? moment(date).locale(lang).format(format) : "";
};

export const convertDateToLocal = (date, speaksSpanish) => {
  const lang = speaksSpanish ? "es" : "en";
  return moment
    .utc(moment.tz(date, serverDateFormat, "America/Chicago"))
    .locale(lang)
    .local();
};
export const convertTimeFromUserTimezoneToCST = (time, sourceTimezone) => {
  const timeInCST = moment.tz(time, 'hh:mma', sourceTimezone).tz("America/Chicago").format('hh:mma');
  return timeInCST
};

export const convertTimeFromCSTToBrowserTz = (time) => {
  const timeInBrowserTz = moment.tz(time, 'HH:mm', "America/Chicago").tz(getBrowserTimezone()).format('HH:mm');
  return timeInBrowserTz
};

export const getBrowserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const timeSinceByTimeZone = (date, speaksSpanish, timezone) => {
  const lang = speaksSpanish ? "es" : "en";
  return moment.tz(date, timezone).locale(lang).from(moment().tz(timezone));
};

export const timeSince = (date, speaksSpanish) => {
  return convertDateToLocal(date, speaksSpanish).fromNow();
};

export const formatDateLocal = date => {
  return convertDateToLocal(date).format(applicationDateFormat);
};

export const formatDateTimeLocal = date => {
  return convertDateToLocal(date).format(applicationDateTimeFormat);
};

export const serverDateFormat = "YYYY-MM-DDTHH:mm:ss";
export const applicationDateFormat = "M/D/YYYY";
export const applicationTimeFormat = "h:mm A";
export const applicationDateTimeFormat = "MMM D, YYYY h:mm A";

export const fromToLocal = (startDate, endDate, translatedTo) =>
  `${convertDateToLocal(startDate).format(
    applicationTimeFormat
  )} ${translatedTo} ${convertDateToLocal(endDate).format(
    applicationTimeFormat
  )}`;

export const fromTo = (startDate, endDate, translatedTo) =>
  `${formatDate(startDate, applicationTimeFormat)} ${translatedTo} ${formatDate(endDate, applicationTimeFormat)}`;

export const isValidToken = token => {
  if (!token || typeof token !== "string") return false;
  const token_expiration = token.replace(/^.*\^/g, "");
  return moment(token_expiration, "M/D/YYYY h:mm:ss a").isAfter(moment());
};

export const isValidPhone = value =>
  value && !/^(\+?)(0|[0-9][0-9]{9,13})$/i.test(value)
    ? "Invalid phone number."
    : undefined;

export const isValidZip = value =>
  value && !/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(value)
    ? "Invalid Zip Code."
    : undefined;

export const formatAddress = ({
  street_address,
  street_address2,
  city,
  state,
  zip
}) => `${street_address} ${street_address2}, ${city}, ${state}, ${zip}`;

export const formatPhone = value => {
  if (!value) {
    return value;
  }
  const phoneDigits = value.replace(/[^\d]/g, "");
  if (phoneDigits.length <= 3) {
    return phoneDigits;
  }
  if (phoneDigits.length <= 7) {
    return `${phoneDigits.slice(0, 3)}-${phoneDigits.slice(3)}`;
  }
  return `${phoneDigits.slice(0, 3)}-${phoneDigits.slice(
    3,
    6
  )}-${phoneDigits.slice(6)}`;
};

export const capitalize = (text, allWords = false) => {
  if (typeof text != "string") return text;
  if (allWords === true) {
    return text.split(" ").map(capitalize).join(" ");
  } else
    return (
      text.toLowerCase().charAt(0).toUpperCase() + text.toLowerCase().slice(1)
    );
};

export const decodeJwt = tokenString => {
  try {
    return jwt_decode(tokenString);
  } catch (e) {
    return {};
  }
};

export const translateUsingBrowser = key => {
  const browserLang =
    window.navigator.language === "es" ? "spanish" : "english";
  try {
    return Translations[key][browserLang];
  } catch (e) {
    console.log(`Could not find translation for ${key}`);
    return key;
  }
};

export const normalizePhone = value => value && value.replace(/\D/g, "");

