import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import useFetch from "hooks/use-fetch";
import { get } from "utils/api";
import { Spinner } from "components/ui";
import ValidatePatientDob from "./authorize-patient";
import NewOrderForm from "./index";
import OrderFormProvider from "./provider";
import { NewOrderErrorPage } from "./error-page";

export const getOrderFormOrigin = urlParams => {
  const params = queryString.parse(urlParams);
  switch (params.origin) {
    case "rotech_portal":
    case "External Portal":
    case "external":
      return "External Portal";
    case "sms":
    case "Patient Portal From SMS":
      return "Patient Portal From SMS";
    case "email":
    case "Patient Portal From Email":
      return "Patient Portal From Email";
    case "mobile":
    case "Mobile App":
      return "Mobile App";
    default:
      return "Patient Portal";
  }
};

const ExternalOrderFormContainer = () => {
  const [patientId, setPatientId] = React.useState();
  const location = useLocation();
  const productLine = "CPAP";

  const tokenString = queryString.parse(location.search)?.token;

  const [errorMessage, setErrorMessage] = React.useState(null);

  const checkTokenApi = useFetch({
    apiFn: token => get("patient_portal/mobile/token_check", token),
    onSuccess: result => {
      if (result.success) {
        setToken(token);
        setPatientId(result.patientId);
      } else {
        setErrorMessage("linkNoLongerValid");
      }
    },
    onError: err => {
      console.error(err);
      setErrorMessage("linkNoLongerValid");
    }
  });

  React.useEffect(() => {
    checkTokenApi.fetchData(tokenString);
  }, [tokenString]);

  const [token, setToken] = React.useState(tokenString);

  const history = useHistory();
  const origin = getOrderFormOrigin(location.search);

  if (errorMessage) return <NewOrderErrorPage errorMessage={errorMessage} />;

  if (checkTokenApi.isFetching)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Spinner />
      </div>
    );

  if (origin == "Mobile App")
    return (
      <OrderFormProvider
        patientId={patientId}
        productLine={productLine}
        token={token}
        origin={origin}
        handleCreateOrderSuccess={() => {
          try {
            if (window && window.parent) {
              window?.parent?.postMessage(
                JSON.stringify({ message: "Order Submitted", data: "" }),
                "*"
              );
            }
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({ message: "Order Submitted", data: "" })
            );
          } catch (err) {
            console.error(err);
          }
          history.push({
            pathname: "/thanks",
            state: {
              message: "Your Order has been submitted",
              shouldPostMessage: origin === "External Portal"
            }
          });
        }}
      >
        <NewOrderForm />
      </OrderFormProvider>
    );

  return (
    <ValidatePatientDob token={token}>
      <OrderFormProvider
        patientId={patientId}
        productLine={productLine}
        token={token}
        origin={origin}
        handleCreateOrderSuccess={() => {
          history.push({
            pathname: "/thanks",
            state: {
              message: "Your Order has been submitted",
              shouldPostMessage: origin === "External Portal"
            }
          });
        }}
      >
        <NewOrderForm />
      </OrderFormProvider>
    </ValidatePatientDob>
  );
};

export default ExternalOrderFormContainer;

export const SMSOrderFormContainer = () => {
  const [patientId, setPatientId] = React.useState();
  const location = useLocation();
  const productLine = "CPAP";

  const authCode = queryString.parse(location.search)?.auth;

  const [errorMessage, setErrorMessage] = React.useState(null);

  const checkAuthCodeApi = useFetch({
    apiFn: authCode => get("patient_portal/mobile/auth_check", authCode),
    onSuccess: result => {
      if (result.success) {
        setToken(token);
        setPatientId(result.patientId);
      } else {
        setErrorMessage("linkNoLongerValid");
      }
    },
    onError: err => {
      console.error(err);
      setErrorMessage("linkNoLongerValid");
    }
  });
  React.useEffect(() => {
    checkAuthCodeApi.fetchData(authCode);
  }, [authCode]);

  const [token, setToken] = React.useState();

  const history = useHistory();
  const origin = getOrderFormOrigin(location.search);

  if (errorMessage) return <NewOrderErrorPage errorMessage={errorMessage} />;

  if (checkAuthCodeApi.isFetching)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <ValidatePatientDob
      authCode={authCode}
      onSuccess={x => {
        setToken(x.token);
      }}
    >
      <OrderFormProvider
        patientId={patientId}
        productLine={productLine}
        token={token}
        origin={origin}
        handleCreateOrderSuccess={message => {
          console.log({ message });
          history.push({
            pathname: "/thanks",
            state: {
              message: "Order Created",
              shouldPostMessage: origin === "External Portal"
            }
          });
        }}
      >
        <NewOrderForm />
      </OrderFormProvider>
    </ValidatePatientDob>
  );
};
