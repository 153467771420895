import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import FormElement from "./form-elements";
import { getEqMissingDysfunctions } from "./validation";
import { capitalize } from "utils/misc";

const Dysfunctions = ({ questions }) => {
  const { values, errors } = useFormikContext();

  const dyfunctionsToAsk =
    questions.Dysfunctions?.filter(({ productType }) => {
      if (values.requested_equipment.some(x => x.includes("tubing")))
        return "tubing";
      else if (values.requested_equipment.some(x => x.includes("filter")))
        return "filter";
      else
        return values.requested_equipment.includes(productType?.toLowerCase());
    }) ?? [];

  const dysfunctionsGroupedByProduct = Object.groupBy(
    dyfunctionsToAsk,
    ({ productType }) => productType?.toLowerCase()
  );

  const equipmentTypesRequested = values.requested_equipment.map(x => {
    if (x.includes("tubing")) return "tubing";
    if (x.includes("filter")) return "filter";
    else return x;
  });

  const eqRequiringDysfunctions = getEqMissingDysfunctions(values, questions);

  return (
    <div>
      {dyfunctionsToAsk.length > 0 && values.requested_equipment.length > 0 && (
        <div>
          <div>
            {Object.keys(dysfunctionsGroupedByProduct)
              .filter(x => equipmentTypesRequested.includes(x))
              .map(productType => (
                <div key={productType}>
                  <h5>
                    {productType != "undefined"
                      ? capitalize(productType, true)
                      : ""}
                  </h5>
                  <div style={{ marginLeft: 20 }}>
                    {dysfunctionsGroupedByProduct[productType].map(question => (
                      <FormElement key={question.id} {...question} />
                    ))}
                  </div>
                  {errors.dysfunctions &&
                    eqRequiringDysfunctions.includes(productType) && (
                      <div
                        className="order-form-warning"
                        style={{
                          padding: "10px",
                          border: "1px solid #ff3333",
                          color: "#ff3333",
                          background: "#ffcccc33",
                          fontWeight: "600",
                          margin: "5px 0"
                        }}
                      >
                        Eligibility to have insurance cover replacement of these
                        items requires the presence of a dysfunction.
                      </div>
                    )}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

Dysfunctions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Dysfunctions;
