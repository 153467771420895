import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { TextField } from "@mui/material";
import { path } from "ramda";
import { useEffect } from "react";
import { formatPhone, normalizePhone } from "utils/misc";

const getPath = name => name.split(".");

// https://material-ui.com/api/text-field/
const PhoneNumberInput = ({ label, name, ...rest }) => {
  const { values, handleBlur, setFieldValue, errors, touched } =
    useFormikContext();
  const namePath = getPath(name);
  const error = path(namePath, touched) && path(namePath, errors);
  const value = path(namePath, values);

  useEffect(() => {
    setFieldValue(name, normalizePhone(value));
  }, [value]);

  const handleInputChange = ({ target: { value: newValue } }) => {
    if (newValue !== value) {
      setFieldValue(name, normalizePhone(newValue));
    }
  };
  return (
    <TextField
      error={!!error}
      label={label}
      name={name}
      value={formatPhone(value)}
      onChange={handleInputChange}
      onBlur={handleBlur}
      helperText={error}
      type="tel"
      margin="normal"
      style={{ width: "100%", margin: 3 }}
      {...rest}
    />
  );
};

PhoneNumberInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default PhoneNumberInput;
