import { HOST } from "environment";
import fetch from "isomorphic-fetch";

export class ApiError extends Error {
  constructor(body, response, action, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    const message = body?.message;
    super(message, ...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = "ApiError";
    this.response = response;
    this.body = body;
    this.action = action;
  }
  notAuthorized = () =>
    !this.action?.publicRoute && this.response?.status === 401;
}

export const logApiError = error => {
  if (process.env.NODE_ENV === "development") console.error(error);
};

export const handleErrors = async (response, action) => {
  if (!response.ok) {
    const body = await response.json();
    throw new ApiError(body, response, action);
  }
  return response;
};

export const handleResponse = async response => {
  const body = await response.json();
  if (Object.prototype.hasOwnProperty.call(body, "success") && !body.success) {
    throw new ApiError(body, response);
  }
  return body;
};

export const get = async (route, authToken) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${authToken}`
    }
  };
  const payload = await fetch(`${HOST}/${route}`, requestOptions)
    .then(error => handleErrors(error, { type: "API_ERROR" }))
    .then(handleResponse);
  return payload;
};

export const post = async (route, body, authToken) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${authToken}`
    }
  };
  const payload = await fetch(`${HOST}/${route}`, requestOptions)
    .then(error => handleErrors(error, { type: "API_ERROR" }))
    .then(handleResponse);
  return payload;
};

async function apiRequest(path, { method = "GET", headers = {}, body } = {}) {
  return await fetch(`${HOST}/${path}`, {
    method,
    headers: { "content-type": "application/json", ...headers },
    body: body ? JSON.stringify(body) : undefined
  })
    .then(handleErrors)
    .then(handleResponse);
}

export default apiRequest;
